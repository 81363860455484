/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import ogImage from "../images/aray_logo_share.png"

function Seo({ description, lang, meta, title, app = false }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        image
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:url`,
                    content: globalHistory.location.href
                },
                {
                    property: `og:title`,
                    content: title || site.siteMetadata?.title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    property: `og:image`,
                    content: site.siteMetadata?.image || ogImage
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.author || ``
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                }
            ].concat(meta)}
        >
            {app ? (
                <script>
                    {`
                    window.onload = function() {
                      if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.araysociety.app';
                      }
                      if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
                        window.location.href = 'https://apps.apple.com/us/app/aray-society/id6445877752';
                      }
                    };
                    `}
                </script>
            ) : null}
        </Helmet>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
}

export default Seo
